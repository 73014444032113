<template>
    <div class="elmain">
        <!--        <el-button type="primary" @click="pldownLoad()">批量下载二维码</el-button>-->
        <el-button type="success" @click="handleClick(null, 'add')">新增设备</el-button>
        <el-table :data="list" border style="width: 100%;    margin-top: 20px;">
            <!-- <el-table-column fixed prop="iot_id" label="机器ID" width="240"></el-table-column> -->
            <el-table-column type="index" label="序号"></el-table-column>
            <el-table-column prop="sortnum" label="排序"></el-table-column>
            <el-table-column prop="iot_id" label="父类设备ID"></el-table-column>
            <el-table-column prop="serial_num" label="序列号"></el-table-column>
   
            <el-table-column prop="nick_name" label="名称"></el-table-column>
            <el-table-column prop="group_name" label="部门"></el-table-column>
            <!--            <el-table-column label="二维码" width="240">-->
            <!--                <template slot-scope="scope">-->
            <!--                    <span :id="'code'+scope.row.id" class="codee"></span>-->
            <!--                    <el-button style="position: absolute;right: 10px;top: 30px;" type="primary"-->
            <!--                               @click="downLoad('code'+scope.row.id,scope.row.nick_name)">下载二维码-->
            <!--                    </el-button>-->
            <!--                </template>-->
            <!--            </el-table-column>-->
            <!--            <el-table-column label="状态" width="120">-->
            <!--                <template slot-scope="scope">-->
            <!--                    <div v-if="!scope.row.is_expire">-->
            <!--                        <span style="color:red;" v-if="scope.row.status == 'offline'">关机</span>-->
            <!--                        <span style="color:#52ea07;" v-else>开机</span>-->
            <!--                    </div>-->
            <!--                    <div v-else>-->
            <!--                        <span style="color:red;">已过期</span>-->
            <!--                    </div>-->

            <!--                </template>-->
            <!--            </el-table-column>-->
            <el-table-column prop="create_time" label="创建时间"
                             :formatter="createTimeFormat"></el-table-column>
            <!--            <el-table-column prop="expire_time" label="到期时间" width="200">-->
            <!-- <template slot-scope="scope">
              <span style="color:52ea07;">{{ parseInt(scope.row.total_online_time  / 60) + "小时" + (scope.row.total_online_time  % 60) + "分钟"}}</span>

        </template> -->
            <!--            </el-table-column>-->
            <!--            <el-table-column label="累计开机时长" width="300">-->
            <!--                <template slot-scope="scope">-->
            <!--                    <span-->
            <!--                        style="color:52ea07;">{{-->
            <!--                            parseInt(scope.row.all_online_time / 60) + "小时" + (scope.row.all_online_time % 60) + "分钟"-->
            <!--                        }}</span>-->
            <!---->
            <!--                </template>-->
            <!--            </el-table-column>-->
            <el-table-column label="操作" width="150">
                <template slot-scope="scope">
                    <el-button @click="handleClick(scope.row, 'edit')" type="warning" size="small">编辑</el-button>
                    <el-button type="danger" @click="deleteData(scope.row)" size="small">删除</el-button>
                    <!--                    <el-button @click="xfClick(scope.row)" type="text" size="small">续费</el-button>-->
                </template>
            </el-table-column>
        </el-table>
        <div class="block">

            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                           :current-page.sync="page" :page-sizes="[5,10, 50, 100]" :page-size="limit"
                           layout="sizes, prev, pager, next" :total="total">
            </el-pagination>
            <!-- 修改框 -->
            <el-dialog title="产品信息" :visible.sync="dialogFormVisible">
                <el-form :model="dialogData">
                    <el-form-item label="设备名称" :label-width="formLabelWidth">
                        <el-input v-model="dialogData.nick_name" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="排序" :label-width="formLabelWidth">
                        <el-input type="number" placeholder='越大越靠前' v-model="dialogData.sortnum"
                                  autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="设备分组" :label-width="formLabelWidth">
                        <el-select v-model="dialogData.company_group_id" placeholder="请选择分组">
                            <el-option v-for="(item,index) in groupList" :key="index" :label="item.name"
                                       :value="item.id">{{ item.name }}
                            </el-option>

                        </el-select>
                    </el-form-item>
                    <!-- <el-form-item label="设备ID" :label-width="formLabelWidth">
                        <el-input v-model="dialogData.ywid" autocomplete="off" :disabled="!ywidEdit"></el-input>
                    </el-form-item> -->
                    <!-- <el-form-item label="父类设备ID" :label-width="formLabelWidth">
                        <el-input v-model="dialogData.parent_iot_id" autocomplete="off" :disabled="!ywidEdit"></el-input>
                    </el-form-item> -->
                    <el-form-item label="父类设备ID" :label-width="formLabelWidth">
                        <el-select v-model="dialogData.parent_iot_id" placeholder="请选择一级设备">
                            <el-option v-for="(item,index) in lv1list" :key="index" :label="item.nick_name"
                                       :value="item.iot_id">{{ item.nick_name }}
                            </el-option>

                        </el-select>
                    </el-form-item>
                    <el-form-item label="序列号(1~16)" :label-width="formLabelWidth">
                        <!-- <el-input v-model="dialogData.serial_num" autocomplete="off" ></el-input> -->
                        <el-input-number v-model="dialogData.serial_num" :min="1" :max="16" :precision="0"></el-input-number>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="dialogFormVisible = false">取 消</el-button>
                    <el-button type="primary" @click="submit">确 定</el-button>
                </div>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import {deviceList, group, putdevicev2, deviceAdd2,deviceListlv1} from "@/service/api";
import {deviceDelete} from "../../service/api";

export default {
    data() {
        return {
            tableData: [],
            page: 1,
            limit: 10,
            list: [],
            total: 0,
            dialogFormVisible: false,
            formLabelWidth: '120px',
            dialogData: {
                id: 0,
                nick_name: '',
                company_group_id: '',
                sortnum: 0,
                parent_iot_id: '',
                serial_num:''

            },
            lv1list:[],
            groupList: [],
            dialogType: 'add',
            ywidEdit: true
        };
    },
    methods: {
        createTimeFormat(row) {
            if (row.create_time > 0) {
                return row.create_time;
            }
            return row.created_at;
        },
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
            this.limit = val;
            this.getlist();
        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
            this.page = val;
            this.getlist();
        },
        submit() {
            if (this.dialogData.company_group_id === '') {
                this.$message.error('请选择分组');
                return;
            }
            if (this.dialogData.parent_iot_id !== '' && this.dialogData.serial_num === '') {
                this.$message.error('请设置序列号');
                return;
            }
            this.dialogData.device_name = this.dialogData.nick_name;
            let handleMethod = putdevicev2;
            if (this.dialogType === 'add') {
                handleMethod = deviceAdd2;
            }
            handleMethod(this.dialogData).then(res => {
                if (res.code === 200) {
                    this.$message({
                        message: '操作成功',
                        type: 'success'
                    });
                    this.getlist();
                    this.dialogFormVisible = false;
                }
            });
        },
        xfClick(row) {
            console.log(row);
        },
        handleClick(row, type) {
            this.dialogType = type;
            this.dialogFormVisible = true;
            if (row) {
                this.dialogData = row;
                this.dialogData.company_group_id = row.group ? row.group.id : '';
                if(row.parent_iot_id){
                    this.ywidEdit = false;
                }
            } else {
                this.dialogData = {
                    id: 0,
                    nick_name: '',
                    company_group_id: '',
                    sortnum: 0,
                    parent_iot_id:'',
                    serial_num:''

                };
                this.ywidEdit = true;
            }
        },
        getlist() {
            deviceList({limit: this.limit, page: this.page}).then(res => {
                this.list = res.data.list;
                this.total = res.data.total;
                // this.getQrCode();
            });
        },
        getQrCode() {
            this.$nextTick(() => {
                this.list.forEach(item => {
                    let qrcode = new this.QRCode('code' + item.id, {
                        width: 80,
                        height: 80,
                        text: 'http://www.cncbkw.com/#/work?iot_id=' + item.iot_id, // 二维码地址
                        colorDark: "#000",
                        colorLight: "#fff"
                    });
                    console.log(qrcode);
                });
            });


        },
        downLoad(id, name) {
            let myCanvas = document.getElementById(id).getElementsByTagName('canvas');
            let a = document.createElement('a');
            a.href = myCanvas[0].toDataURL('image/png');
            a.download = name + '二维码';
            a.click();
            this.$message({
                message: "正在进行下载保存",
                type: 'success'
            });

        },
        pldownLoad() {
            this.$nextTick(() => {
                this.list.forEach(item => {
                    console.log(item);
                    this.downLoad('code' + item.id, item.nick_name);
                });
            });
        },
        deleteData(row) {
            let that = this;
            this.$confirm('此操作将永久删除该记录, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let data = {
                    id: row.id
                };
                deviceDelete(data).then(res => {
                    if (res.code === 200) {
                        that.getlist();
                        that.$message({
                            type: 'success',
                            message: '删除成功!'
                        });
                    }
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        }
    },
    mounted() {
        this.getlist();
        group().then(res => {
            this.groupList = res.data.list;
            console.log(this.groupList);
        });
        deviceListlv1().then(res => {
                this.lv1list = res.data.list;
               
              
            });
    }
};
</script>
<style scoped>
.elmain {
    padding: 50px;
}

.codee img {
    display: inline-block;
}

@media screen and (max-width: 750px) {
    .main-conent {
        margin: 10px;
    }

    .elmain {
        padding: 10px;
    }

    .el-dialog {
        width: 90% !important;
    }

    .el-dialog__body {
        padding: 30px 5px;
        color: #606266;
        font-size: 14px;
        word-break: break-all;
    }
}
</style>
